/* components/OTPInput.module.css */

.inputStyle {
  width: 40px;
  height: 40px;
  margin-right: 0.5rem;
  text-align: center;
  padding: 0.5rem;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 19px 0px rgba(0, 13, 56, 0.1);
  border: none;
  margin-bottom: 25px;
}

@media (max-width: 640px) {
  .inputStyle {
    width: 40px;
    height: 40px;
    padding: 0.15rem;
    margin-right: 0.2rem;
    border-radius: 6px;
    margin-bottom: 20px;
  }
}